import React, { useState, useEffect } from "react"
import { Link, graphql } from "gatsby"
import Layout from "../../components/layout"
import HeroWithoutImage from "../../components/Hero/HeroWithoutImage"
import SEO from "../../components/seo"
import { withPreview } from "gatsby-source-prismic"
import { Image } from "../../components/VindiciaComponents"
import { useLocation } from "@reach/router"
import { getCTA, getSlug, topicTypes, contentTypes } from "../../utils/resources"

const ResourceItem = ({ item }) => {
  return (
    <div key={item.uid} data-filter-item={item.contentType}>
      <Link
        to={`/resources/${getSlug(item.contentType)}/${item.uid}`}
        className="uk-display-block uk-card uk-card-default uk-card-small uk-link"
      >
        <div className="uk-card-media-top">
          <Image
            src={item.data.thumbnail.fluid.src}
            srcSet={item.data.thumbnail.fluid.srcSet}
            alt={item.data.thumbnail.alt}
            style={{
              height: "240px",
              width: "100%",
              objectFit: "cover",
            }}
          />
        </div>
        <div className="uk-card-body uk-text-left">
          <p className="uk-card-title">
            {item.data.title ? item.data.title.text : item.data.hero_title.text}
          </p>
        </div>
        <div className="uk-card-footer uk-text-left">
          <p className="cta-link">
            {getCTA(item.contentType)} <span uk-icon="icon: arrow-right"></span>
          </p>
        </div>
      </Link>
    </div>
  )
}

const ResourceSection = ({ title, slug, data, allResources = false, allCount = 0 }) => {
  const [count, setCount] = useState(allResources ? 12 : 3)

  return (
    <section
      id={`resources-materials-${slug}`}
      className="uk-section uk-margin-small uk-padding-small"
    >
      <div className="uk-container">
        <div className="uk-grid uk-grid-collapse" data-uk-grid>
          <div className="uk-flex uk-flex-between uk-text-center uk-width">
            <h3 className="uk-display-inline uk-margin-remove-bottom">{title}</h3>
            {allResources && allCount && (
              <p className="uk-margin-remove-top">
                Showing {data.length} of {allCount}
              </p>
            )}
          </div>
          {!allResources && (
            <div className="uk-width-expand uk-text-right uk-h4">
              <Link className="cta-link" to={`/resources/${slug}`}>
                View all
              </Link>
            </div>
          )}
        </div>
        <div
          className="uk-grid uk-child-width-1-3@s uk-margin-top uk-margin-large-bottom uk-grid uk-grid-stack"
          data-uk-grid
          data-uk-height-match="target: > div > .uk-card > .uk-card-body"
        >
          {data.map((item, i) => {
            if (i < count) return <ResourceItem item={item} />
          })}
        </div>
        <div className="uk-grid uk-flex-center">
          {allResources && data.length > 12 && (
            <button
              className="uk-button uk-button-large uk-button-primary uk-border-pill"
              onClick={ev => {
                setCount(data.length)
                if (ev.target instanceof Element) {
                  ev.target.classList.add("uk-hidden")
                }
              }}
            >
              Show More
            </button>
          )}
        </div>
      </div>
    </section>
  )
}

const Resources = ({ data }) => {
  const whitepapers = data.allPrismicWhitepapers.edges.map(page => ({
    ...page.node,
    contentType: "whitepapers",
  }))
  const ebooks = data.allPrismicEbooks.edges.map(page => ({ ...page.node, contentType: "ebooks" }))
  const reports = data.allPrismicReports.edges.map(page => ({
    ...page.node,
    contentType: "reports",
  }))
  const datasheets = data.allPrismicDatasheets.edges.map(page => ({
    ...page.node,
    contentType: "datasheets",
  }))
  const infographics = data.allPrismicInfographics.edges.map(page => ({
    ...page.node,
    contentType: "infographics",
  }))
  const videos = data.allPrismicVideo.edges.map(page => ({ ...page.node, contentType: "videos" }))
  const guides = data.allPrismicGuidePage.edges.map(page => ({
    ...page.node,
    contentType: "guides",
  }))

  const allResources = [
    ...whitepapers,
    ...ebooks,
    ...reports,
    ...datasheets,
    ...infographics,
    ...videos,
    ...guides,
  ].sort((a, b) => (a.last_publication_date > b.last_publication_date ? a : b))

  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)

  const [topicFilter, setTopicFilter] = useState([])
  const [contentFilter, setContentFilter] = useState([])

  const [isShowAll, setIsShowAll] = useState(false)
  const [filteredResources, setFilteredResources] = useState(allResources)

  const toggleActiveClass = () => {
    const filterItems = document.querySelectorAll(".filter-item")
    const filter = [...topicFilter, ...contentFilter]

    filterItems.forEach(item => item.classList.remove("uk-text-bold", "active"))

    filterItems.forEach(item => {
      filter.map(filter => {
        if (item.dataset.filter === filter) {
          item.classList.add("uk-text-bold", "active")
        }
      })
    })
  }

  const toggleFilter = (ev, value, type) => {
    const filter = type === "topic" ? topicFilter : contentFilter
    const isHaveFilter = filter.filter(item => item === value)

    if (isHaveFilter.length) {
      type === "topic"
        ? setTopicFilter(prevState => prevState.filter(item => item !== value))
        : setContentFilter(prevState => prevState.filter(item => item !== value))
    } else {
      type === "topic"
        ? setTopicFilter(prevState => [...prevState, value])
        : setContentFilter(prevState => [...prevState, value])
    }
  }

  const removeFilter = value => {
    if (value !== "all") {
      setTopicFilter(prevState => prevState.filter(item => item !== value))
      setContentFilter(prevState => prevState.filter(item => item !== value))
    } else {
      setTopicFilter([])
      setContentFilter([])
    }
  }

  const getFilteredResources = () => {
    let filtered = []
    let filteredByContent = []

    if (contentFilter.length) {
      contentFilter.forEach(content => {
        filteredByContent.push(...allResources.filter(resource => content === resource.contentType))
      })
    } else {
      filteredByContent.push(...allResources)
    }

    if (topicFilter.length) {
      topicFilter.forEach(topic => {
        filtered.push(
          ...filteredByContent.filter(
            resource =>
              topicTypes[topic] === resource.data.topic ||
              topicTypes[topic] === resource.data.second_topic,
          ),
        )
      })
    } else {
      filtered.push(...filteredByContent)
    }

    return [...new Set(filtered)]
  }

  const generateLabelItems = (data, type) => {
    return data.map(item => {
      const text = type === "content" ? contentTypes[item] : topicTypes[item]

      return (
        <div
          className="uk-label uk-text-capitalize uk-text-primary uk-background-default uk-text-bold uk-box-shadow-hover-small"
          onClick={() => removeFilter(item)}
          style={{ cursor: "pointer" }}
        >
          {text} ×
        </div>
      )
    })
  }

  useEffect(() => {
    const queryTopic = queryParams.get("topic")?.split(",")
    const queryContentType = queryParams.get("cp")?.split(",")

    if (queryTopic) {
      setTopicFilter(queryTopic)
    }

    if (queryContentType) {
      setContentFilter(queryContentType)
    }
  }, [])

  useEffect(() => {
    topicFilter.length
      ? queryParams.set("topic", topicFilter.join(","))
      : queryParams.delete("topic")
    contentFilter.length ? queryParams.set("cp", contentFilter.join(",")) : queryParams.delete("cp")

    const newUrl = `${location.pathname}?${queryParams.toString()}`
    window.history.pushState({}, "", newUrl)

    toggleActiveClass()

    setIsShowAll(!!topicFilter.length || !!contentFilter.length)
    setFilteredResources(getFilteredResources())
  }, [topicFilter, contentFilter])

  return (
    <Layout>
      <SEO
        title="Resources &amp; Insights"
        description="Unlock subscription insights with Vindicia's Resource Center. Access whitepapers, reports, videos, and more to optimize your business. Leverage Vindicia Retain for customer retention success."
        keywords="subscription insights, whitepapers, reports, videos, datasheets, infographics, eBooks, subscription optimization, subscription business models, customer retention, vindicia retain, subscription economy, resource center, subscription analytics, revenue optimization"
      />
      <HeroWithoutImage title={"Resource Center"} />
      <div id="filter" />
      <div
        className="uk-sticky uk-background-default"
        uk-sticky={"offset: 80; bottom: true; media: 640; cls-active: uk-box-shadow-medium;"}
      >
        <div
          className="uk-container uk-flex uk-flex-wrap uk-flex-wrap-around uk-padding-small uk-flex uk-flex-middle"
          style={{ gap: "15px" }}
        >
          <span>Filter by:</span>
          <button className="uk-button uk-button-primary uk-border-pill" type="button">
            Topic
          </button>
          <div uk-dropdown="mode: hover; pos: bottom-left">
            <ul className="uk-nav uk-dropdown-nav">
              {Object.entries(topicTypes).map((item, i) => (
                <li
                  className="filter-item"
                  onClick={ev => toggleFilter(ev, item[0], "topic")}
                  data-filter={item[0]}
                >
                  <span className="uk-link">{item[1]}</span>
                </li>
              ))}
            </ul>
          </div>
          <button className="uk-button uk-button-primary uk-border-pill" type="button">
            Content Type
          </button>
          <div uk-dropdown="mode: hover; pos: bottom-left">
            <ul className="uk-nav uk-dropdown-nav">
              {Object.entries(contentTypes).map((item, i) => (
                <li
                  className="filter-item"
                  onClick={ev => toggleFilter(ev, item[0], "content")}
                  data-filter={item[0]}
                >
                  <span className="uk-link">{item[1]}</span>
                </li>
              ))}
            </ul>
          </div>
          {contentFilter.length || topicFilter.length ? (
            <a
              className="uk-button uk-button-primary uk-border-pill"
              onClick={() => removeFilter("all")}
              href="#filter"
              data-uk-scroll="offset:100; duration: 800"
            >
              Clear Filters
            </a>
          ) : null}
        </div>
      </div>
      <div
        className="uk-container uk-flex uk-flex-wrap uk-flex-wrap-around uk-padding-small"
        style={{ gap: "10px" }}
      >
        {generateLabelItems(contentFilter, "content")}
        {generateLabelItems(topicFilter, "topic")}
      </div>
      {!isShowAll ? (
        <>
          <ResourceSection
            key="white-papers"
            title="Whitepapers"
            slug="white-papers"
            data={whitepapers}
          />
          <ResourceSection
            key="Reports"
            title="Reports"
            slug="analyst-reports-reviews"
            data={reports}
          />
          <ResourceSection key="videos" title="Videos" slug="videos" data={videos} />
          <ResourceSection
            key="datasheets"
            title="Datasheets"
            slug="datasheets"
            data={datasheets}
          />
          <ResourceSection key="ebooks" title="eBooks" slug="ebooks" data={ebooks} />
          <ResourceSection
            key="infographics"
            title="Infographics"
            slug="infographics"
            data={infographics}
          />
          <ResourceSection key="guides" title="Guides" slug="guides" data={guides} />
        </>
      ) : (
        <ResourceSection
          key="all"
          title="Resources"
          slug=""
          data={filteredResources}
          allResources
          allCount={allResources.length}
        />
      )}
      <div className="uk-grid uk-margin-large-bottom uk-flex-center">
        {!isShowAll && (
          <a
            className="uk-button uk-button-large uk-button-primary uk-border-pill"
            onClick={() => setIsShowAll(true)}
            href="#filter"
            data-uk-scroll="offset:100; duration: 800"
          >
            View All Resources
          </a>
        )}
      </div>
    </Layout>
  )
}

export default withPreview(Resources)

export const resourcesQuery = graphql`
  {
    allPrismicWhitepapers(sort: { fields: last_publication_date, order: DESC }) {
      edges {
        node {
          data {
            topic
            second_topic
            thumbnail {
              fluid(maxWidth: 800) {
                ...GatsbyPrismicImageFluid_noBase64
              }
            }
            description {
              html
            }
            link {
              link_type
              target
            }
            title {
              html
              text
            }
          }
          uid
          last_publication_date
        }
      }
    }
    allPrismicEbooks(sort: { fields: last_publication_date, order: DESC }) {
      edges {
        node {
          data {
            topic
            second_topic
            thumbnail {
              fluid(maxWidth: 800) {
                ...GatsbyPrismicImageFluid_noBase64
              }
            }
            description {
              html
            }
            link {
              link_type
              target
            }
            title {
              html
              text
            }
          }
          uid
          last_publication_date
        }
      }
    }
    allPrismicReports(sort: { fields: last_publication_date, order: DESC }) {
      edges {
        node {
          data {
            topic
            second_topic
            thumbnail {
              fluid(maxWidth: 800) {
                ...GatsbyPrismicImageFluid_noBase64
              }
            }
            description {
              html
            }
            link {
              link_type
              target
            }
            title {
              html
              text
            }
          }
          uid
          last_publication_date
        }
      }
    }
    allPrismicInfographics(sort: { fields: last_publication_date, order: DESC }) {
      edges {
        node {
          data {
            topic
            second_topic
            thumbnail {
              fluid(maxWidth: 800) {
                ...GatsbyPrismicImageFluid_noBase64
              }
            }
            description {
              html
            }
            title {
              html
              text
            }
          }
          uid
          last_publication_date
        }
      }
    }
    allPrismicDatasheets(sort: { fields: last_publication_date, order: DESC }) {
      edges {
        node {
          data {
            topic
            second_topic
            thumbnail {
              fluid(maxWidth: 800) {
                ...GatsbyPrismicImageFluid_noBase64
              }
            }
            description {
              html
            }
            link {
              link_type
              target
            }
            title {
              html
              text
            }
          }
          uid
          last_publication_date
        }
      }
    }
    allPrismicVideo(sort: { fields: last_publication_date, order: DESC }) {
      edges {
        node {
          data {
            topic
            second_topic
            thumbnail {
              fluid(maxWidth: 800) {
                ...GatsbyPrismicImageFluid_noBase64
              }
            }
            hero_title {
              html
              text
            }
          }
          uid
          last_publication_date
        }
      }
    }
    allPrismicGuidePage(sort: { fields: last_publication_date, order: DESC }) {
      edges {
        node {
          data {
            topic
            second_topic
            thumbnail {
              fluid(maxWidth: 800) {
                ...GatsbyPrismicImageFluid_noBase64
              }
            }
            title {
              html
              text
            }
            description {
              html
            }
          }
          uid
          last_publication_date
        }
      }
    }
  }
`
